import { Route, Routes } from "react-router-dom";
import { LayoutHome } from "./layout";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<LayoutHome />} />
    </Routes>
  );
}

export default App;
