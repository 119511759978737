"use client";
import React, { useState } from "react";
import Establishment from "../Footer /establishment";
import Description from "../Footer /description";
import Copyright from "../Footer /copyright";

export function FooterComponent() {
  return (
    <footer className="footer-contenair">
      <Establishment />
      <Description />
      <Copyright />
    </footer>
  );
}
