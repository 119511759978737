import { routeType } from "./utils/types";
import { lazy } from "react";

const Home = lazy(() => import("./app/page"));
const Ets = lazy(() => import("./app/ets/page"));
const Article = lazy(() => import("./app/articles/page"));
const ShowArticle = lazy(() => import("./app/articles/[uid]/page"));

export const routes: routeType[] = [
  { label: "Accueil", path: "/", component: Home, isMenu: true },
  { label: "Actualités", path: "/articles", component: Article, isMenu: true },
  { label: "Etablissements", path: "/ets", component: Ets, isMenu: true },
  { label: "", path: "articles/:uid", component: ShowArticle },
];
