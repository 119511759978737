import { cms } from "./authConfig";
import Crud from "./crud";

class TeamService extends Crud {
  url = "ym_team";
  destroy(id: string | number) {
    return this.axios.delete(`${this.url}/${id}`);
  }
  get(id: string | number) {
    return this.axios.get(`${this.url}/${id}`);
  }
  getAll() {
    return this.axios.get(`${this.url}`);
  }
  getByKey(query: string) {
    return cms.get(`${this.url}?${query}`);
  }
  store(data: any) {
    return this.axios.post(`${this.url}`, data);
  }
  update(id: string | number, data: any) {
    return this.axios.put(`${this.url}/${id}`, data);
  }
}
const teamService = new TeamService();
export default teamService;
