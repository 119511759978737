"use client";
import { Button } from "antd";
import React from "react";
import $ from "jquery";
import { isEmpty } from "lodash";

export function ScrollToTop() {
  const [visible, setVisible] = React.useState(false);

  $(".htag-link").on("click", function (e: any) {
    const href = $(this)?.attr("href");
    if (!isEmpty(href)) {
      $("html, body").animate(
        {
          //@ts-ignore
          scrollTop: $(href).offset().top,
        },
        "slow",
      );
    }
  });

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (typeof window !== "undefined") {
        window.scrollY > 10 ? setVisible(true) : setVisible(false);
      }
    };
    if (typeof window !== "undefined") {
      window?.addEventListener("scroll", toggleVisibility);
    }

    // clear the listener on component unmount
    return () => {
      if (typeof window !== "undefined") {
        window?.removeEventListener("scroll", toggleVisibility);
      }
    };
  }, []);

  React.useEffect(() => {
    $(".htag-link").on("click", function (e: any) {
      const href = $(this)?.attr("href");
      if (!isEmpty(href)) {
        $("html, body").animate(
          {
            //@ts-ignore
            scrollTop: $(href).offset().top,
          },
          "slow",
        );
      }
    });
  }, []);

  const onScrollToTop = () => {
    if (typeof window !== "undefined") {
      visible &&
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    }
  };

  return (
    <div className={`scroll-to-top ${visible ? "opacity--100" : "opacity--0"}`}>
      <Button
        onClick={onScrollToTop}
        shape="circle"
        style={{
          boxShadow:
            "1px 1px 1px #FFF,0px 1px 1px #FFF,1px 0px 1px #FFF,0px 0px 1px #FFF",
        }}
        type="default"
      >
        <i className="fa-sharp fa-solid fa-chevron-up"></i>
      </Button>
    </div>
  );
}
