import { upperFirst, upperCase, isEmpty, toUpper, toLower } from "lodash";
import centerService from "../../services/centerService";
import divisionService from "../../services/divisionService";
import sectorService from "../../services/sectorService";
import subDivisionService from "../../services/subDivisionService";
import userService from "../../services/userService";
import authService from "../../services/authService";
import {
  setArticles,
  setLoading,
  setMedias,
  setMissions,
  setTeams,
  setTopArticles,
  setUserProfil,
} from "../../redux/userReducer";
import { setCenters } from "../../redux/centerReducer";
import { setAllSectors } from "../../redux/sectorReducer";
import { setDivisions } from "../../redux/divisionReducer";
import { setSubDivisions } from "../../redux/subDivisionReducer";
import missionService from "../../services/missionService";
import teamService from "../../services/teamService";
import mediaService from "../../services/mediaService";
import articleService from "../../services/articleService";
import { allArticle, stickyArticle } from "./graphql";

class UserHelper {
  private key: string = "@yekola_user";
  private setting_allow_sync_contacts: string =
    "@yekola_setting@allow_sync_contact";
  private setting_contact_sync_date: string =
    "@yekola_setting@contact_sync_date";

  async setCurrentUser(user: any, dispatch?: any) {
    let result = false;
    if (typeof dispatch === "function") {
      let item: any = user;
      await userService
        .get(user?.id)
        .then((response) => {
          item = response.data;
          result = true;
        })
        .catch(() => {});
      dispatch(setUserProfil(item));
    }
    await localStorage.setItem(this.key, JSON.stringify(user));
    return result;
  }

  getUserName(user: any) {
    return `${upperFirst(user?.firstName ?? user?.name)} ${upperCase(
      user?.lastName ?? user?.lastname,
    )} ${upperCase(user?.postName ?? user?.postname ?? "")}`;
  }

  setLogout(dispatch?: any) {
    localStorage.removeItem(this.key);
    authService.logout();
    if (typeof dispatch === "function") {
      dispatch(setUserProfil({}));
    }
  }

  async getUserProfil() {
    let item: any = await localStorage.getItem(this.key);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async setUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
    value: any,
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    await localStorage.setItem(elementKey, JSON.stringify(value));
  }

  async getUserSetting(
    type: "setting-contact-allow-sync" | "setting-contact-sync-date",
  ) {
    let elementKey = "";
    if (type === "setting-contact-allow-sync") {
      elementKey = this.setting_allow_sync_contacts;
    } else if (type === "setting-contact-sync-date") {
      elementKey = this.setting_contact_sync_date;
    }
    let item: any = await localStorage.getItem(elementKey);
    if (item != null) {
      const data = JSON.parse(item);
      return data;
    }
    return null;
  }

  async getCenters(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await centerService
      .getByKey(``)
      .then((response) => {
        items = centerService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setCenters(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getSectors(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];

    //if (!isEmpty(user)) {
    await sectorService
      .getByKey(`order[name]=asc&pagination=false`)
      .then((response) => {
        items = sectorService.getData(response);
        items = items.sort((a: any, b: any) =>
          toLower(a.name).localeCompare(toLower(b.name)),
        );
        if (typeof dispatch === "function") {
          dispatch(setAllSectors(items));
        }
      })
      .catch(() => {});
    //}
    return items;
  }

  async getDivisions(dispatch?: any) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await divisionService
      .getByKey(``)
      .then(async (response) => {
        items = divisionService.getData(response);
        const list = await this.getSubDivisions();
        await Promise.all(
          items.map((p) => {
            const data = list.filter((x) => x.division?.id === p.id);
            p.subDivisions = data;
          }),
        );
        if (typeof dispatch === "function") {
          dispatch(setDivisions(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async getSubDivisions(dispatch?: any, query?: string) {
    const user = await this.getUserProfil();
    let items: any[] = [];
    await subDivisionService
      .getByKey(`${query ?? ""}`)
      .then((response) => {
        items = subDivisionService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setSubDivisions(items));
        }
      })
      .catch(() => {});
    return items;
  }

  async initData(dispatch?: any) {
    this.getMedia(dispatch);
    await this.getCenters(dispatch);
    await this.getDivisions(dispatch);
    await this.getSubDivisions(dispatch);
    await this.getArticles(dispatch);
    this.getTeam(dispatch);
    await this.getMissions(dispatch);
    if (typeof dispatch === "function") {
      dispatch(setLoading(false));
    }
  }

  getTitle(user: any) {
    const { firstName, lastName } = user;
    let result = "";
    if ((firstName ?? "").length > 0) {
      result = firstName.substring(0, 1);
    }
    if ((lastName ?? "").length > 0) {
      result += lastName.substring(0, 1);
    }
    return toUpper(result);
  }

  addMeta(
    value: string,
    name: string,
    type: "string" | "phone" | "text" | "email" | "web",
  ) {
    let meta = null;
    if (!isEmpty(value)) {
      meta = {
        metaKey: name,
        metaValue: value,
        metaType: type,
      };
    }
    return meta;
  }

  getSumPayment(items: any[]) {
    let result = "";
    const elements: any[] = [];
    items.forEach((p) => {
      const index = elements.findIndex((x) => x.currency === p.currency);
      if (index === -1) {
        elements.push({ currency: p.currency, amount: p.amount });
      } else {
        let element: any = elements[index];
        element.amount = p.amount + element.amount;
        elements[index] = element;
      }
    });
    let i = 0;
    elements.forEach((p) => {
      const amount = new Intl.NumberFormat("us-US", {
        maximumSignificantDigits: 2,
      }).format(p.amount);
      result += `${amount} ${toUpper(p.currency)} `;
      if (i < elements.length - 1) {
        result += " + ";
      }
      i++;
    });
    return result;
  }

  async getMissions(dispatch: any) {
    let result: any[] = [];
    await missionService
      .getByKey(``)
      .then((response) => {
        result = response.data;
        dispatch(setMissions(result));
      })
      .catch(() => {});
    return result;
  }

  async getArticles(dispatch: any) {
    let result: any[] = [];
    articleService
      .graphql(stickyArticle)
      .then((response) => {
        const data = response.data?.posts?.edges ?? [];
        const list = data.map((p: any) => p.node);
        dispatch(setTopArticles(list));
      })
      .catch(() => {});
    await articleService
      .graphql(allArticle, { count: 20 })
      .then((response) => {
        const data = response.data?.posts?.edges ?? [];
        const list = data.map((p: any) => p.node);
        result = list;
        dispatch(setArticles(list));
      })
      .catch(() => {});
    return result;
  }

  async getTeam(dispatch: any) {
    let result: any[] = [];
    await teamService
      .getByKey(``)
      .then((response) => {
        result = response.data;
        dispatch(setTeams(result));
      })
      .catch(() => {});
    return result;
  }

  async getMedia(dispatch: any) {
    let result: any[] = [];
    await mediaService
      .getByKey(``)
      .then((response) => {
        result = response.data;
        dispatch(setMedias(result));
      })
      .catch(() => {});
    return result;
  }
}

const userHelper = new UserHelper();

export default userHelper;
