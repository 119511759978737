"use client";

import { Row, Col, Typography, Button } from "antd";

const { Title } = Typography;
const { Paragraph } = Typography;

const Description = () => {
  return (
    <div>
      <Row gutter={10} justify="space-around">
        <Col xs={24} md={8}>
          <div className="description">
            <div className="img_container">
              <img
                style={{ width: "100%" }}
                src={require("../assets/images/logo.png")}
                alt="aaaa"
              />
            </div>
            <Paragraph className="text-1">
              {`Le Ministère de la Formation Professionnelle est une institution gouvernementale chargée de la mise en œuvre et de la gestion des politiques et programmes de formation professionnelle.`}
            </Paragraph>
          </div>
          <div className="social-media">
            <Button shape="circle" href="" type="primary" icon="">
              <i className="fa-brands fa-facebook"></i>
            </Button>
            <Button shape="circle" href="" icon="">
              <i className="fa-brands fa-square-x-twitter"></i>
            </Button>
            <Button
              shape="circle"
              href=""
              style={{ background: "green" }}
              icon=""
            >
              <i className="fa-duotone fa-phone" style={{ color: "#FFF" }}></i>
            </Button>
            <Button
              shape="circle"
              href="mailto:contact@formation.gouv.cd"
              icon=""
              title="E-mail"
            >
              <i className="fa-sharp fa-solid fa-envelope"></i>
            </Button>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <div className="title_adresse">
            <span
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 22,
                display: "block",
                fontWeight: "bold",
              }}
            >
              {`Notre adresse`}
            </span>
            <Paragraph className="text-2" style={{ textAlign: "center" }}>
              6ème étage, Immeuble Kasai, Boulevard du 30 juin, Place Royal
              <br />
              Kinshasa/Gombe
            </Paragraph>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Description;
