import { gql } from "@apollo/client";

export const stickyArticle = gql`
  query stickies {
    posts(first: 3, where: { onlySticky: true }) {
      edges {
        node {
          id
          title
          content
          excerpt
          date
          yekolaArticle {
            featured {
              node {
                mediaItemUrl
                mediaDetails {
                  sizes {
                    __typename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const allArticle = gql`
  query articles($count: Int) {
    posts(first: $count, where: { onlySticky: false }) {
      edges {
        node {
          id
          title
          content
          excerpt
          date
          yekolaArticle {
            featured {
              node {
                mediaItemUrl
                mediaDetails {
                  sizes {
                    __typename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const showArticle = gql`
  query article($id: ID!) {
    post(id: $id) {
      id
      title
      content
      excerpt
      yekolaArticle {
        featured {
          node {
            mediaItemUrl
            mediaDetails {
              sizes {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;
