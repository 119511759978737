import { Axios, AxiosResponse } from "axios";
import http, { axiosWeb } from "./authConfig";
import https from "./apiConfig";
import {
  ApolloClient,
  InMemoryCache,
  OperationVariables,
} from "@apollo/client";

export default abstract class Crud {
  abstract getAll(): any;
  abstract get(id: number | string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract getByKey(query: string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract store(data: object | any): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract update(
    id: number | string, // eslint-disable-line no-unused-vars
    data: object | any, // eslint-disable-line no-unused-vars
  ): Promise<AxiosResponse>;
  abstract destroy(id: number | string): Promise<AxiosResponse>; // eslint-disable-line no-unused-vars
  abstract url: string;
  protected axios: Axios = https;
  protected axiosNoConnected: Axios = http;
  protected axiosApiWeb: Axios = axiosWeb;
  public getData(response: AxiosResponse<any, any>): [] | any[] {
    if (typeof response.data !== "object") {
      return [];
    }
    return response.data["hydra:member"];
  }
  public getTotal(response: AxiosResponse<any, any>): number {
    if (typeof response.data !== "object") {
      return 0;
    }
    return response.data["hydra:totalItems"];
  }
  public getError(reason: any): string {
    if (typeof reason?.response !== "object") {
      return "";
    }
    const data = reason?.response?.data;
    if (typeof data !== "object") {
      return "";
    }
    return data["hydra:description"];
  }

  public async graphql(query: any, variables?: OperationVariables) {
    const client = new ApolloClient({
      uri: "https://cms.kotanga.online/wp/graphql",
      cache: new InMemoryCache(),
      headers: {
        //Authorization: "Bearer " + token,
      },
    });
    return client.query({ query: query, variables });
  }
}
