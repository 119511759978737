import React, { Suspense } from "react";
import { BootstrapNavigation } from "../components/navigation/navigation";
import { FooterComponent } from "../components/footer";
import { ScrollToTop } from "../components/scrollToTop";
import { LoginComponent } from "../components/login";
import { Route, Routes } from "react-router-dom";
import { routes } from "../routes";
import { FallbackScreen } from "./fallback";

export function LayoutHome() {
  return (
    <Suspense fallback={<FallbackScreen/>}>
      <BootstrapNavigation />
      <main
        style={{
          overflowX: "hidden",
          maxWidth: "100%",
          minHeight: "90vh",
        }}
      >
        <Routes>
          {routes.map((p, i) => (
            <Route
              path={p.path}
              element={<p.component />}
              key={`screen_${i}`}
            />
          ))}
        </Routes>
      </main>
      <FooterComponent />
      <ScrollToTop />
      {<LoginComponent />}
    </Suspense>
  );
}

const ProgressBar = () => (
  <div className="progress-bar">
    <div className="progress"></div>
  </div>
);
