import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface subDivisionData {
  current: any;
  list: any[];
  all: any[];
  profil: any;
}

const initialState: subDivisionData = {
  current: {},
  all: [],
  list: [],
  profil: {},
};

export const subDivisionSlice = createSlice({
  name: "sub_division",
  initialState,
  reducers: {
    setSubDivision: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setSubDivisionProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setSubDivisions: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllSubDivisions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const {
  setAllSubDivisions,
  setSubDivision,
  setSubDivisionProfil,
  setSubDivisions,
} = subDivisionSlice.actions;
export default subDivisionSlice.reducer;
