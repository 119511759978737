import { ThemeConfig } from "antd";
import { colorPrimary } from "./color";

const theme: ThemeConfig = {
  components: {
    Button: {
      fontSizeXL: 64,
      controlHeight: 40,
    },
    Input: {
      controlHeight: 40,
    },
    Select: {
      controlHeight: 40,
    },
    Segmented: {
      controlHeight: 40,
    },
  },
  token: {
    colorPrimary: colorPrimary,
    fontSize: 16,
    fontFamily: "Poppins",
  },
};

export { theme as lightTheme };
