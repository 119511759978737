import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  current: any;
  list: any[];
  all: any[];
  newLearners: any[];
  profil: any;
  loading: boolean;
  floatUrl?: string;
  missions: any[];
  teams: any[];
  medias: any[];
  articles: any[];
  topArticles: any[];
}

const initialState: userData = {
  current: {},
  all: [],
  list: [],
  profil: {},
  newLearners: [],
  loading: true,
  missions: [],
  teams: [],
  medias: [],
  articles: [],
  topArticles: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setUserProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setNewUsers: (state, action: PayloadAction<any[]>) => {
      state.newLearners = action.payload;
    },
    setAllUsers: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFloatUrl: (state, action: PayloadAction<string>) => {
      state.floatUrl = action.payload;
    },
    setMissions: (state, action: PayloadAction<any[]>) => {
      state.missions = action.payload;
    },
    setArticles: (state, action: PayloadAction<any[]>) => {
      state.articles = action.payload;
    },
    setTopArticles: (state, action: PayloadAction<any[]>) => {
      state.topArticles = action.payload;
    },
    setTeams: (state, action: PayloadAction<any[]>) => {
      state.teams = action.payload;
    },
    setMedias: (state, action: PayloadAction<any[]>) => {
      state.medias = action.payload;
    },
  },
});
export const {
  setAllUsers,
  setUser,
  setUserProfil,
  setUsers,
  setNewUsers,
  setLoading,
  setFloatUrl,
  setMissions,
  setTeams,
  setMedias,
  setArticles,
  setTopArticles,
} = userSlice.actions;
export default userSlice.reducer;
