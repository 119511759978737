import Crud from "./crud";

class UserService extends Crud {
  url = "users";
  destroy(id: string | number) {
    return this.axios.delete(`${this.url}/${id}`);
  }
  get(id: string | number) {
    return this.axios.get(`${this.url}/${id}`);
  }
  getAll() {
    return this.axios.get(`${this.url}`);
  }
  getByKey(query: string) {
    return this.axios.get(`${this.url}?${query}`);
  }
  store(data: any) {
    return this.axiosNoConnected.post(`new/user`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  login(data: any) {
    return this.axiosApiWeb.post(`login`, data);
  }
  update(id: string | number, data: any) {
    return this.axios.put(`${this.url}/${id}`, data);
  }
  updatePhoto(data: any) {
    return this.axios.post(`picture/update`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const userService = new UserService();
export default userService;
