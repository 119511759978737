import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface divisionData {
  current: any;
  list: any[];
  all: any[];
  profil: any;
}

const initialState: divisionData = {
  current: {},
  all: [],
  list: [],
  profil: {},
};

export const divisionSlice = createSlice({
  name: "divisions",
  initialState,
  reducers: {
    setDivision: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setDivisionProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setDivisions: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllDivisions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllDivisions, setDivision, setDivisionProfil, setDivisions } =
  divisionSlice.actions;
export default divisionSlice.reducer;
