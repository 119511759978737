import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface sectorData {
  current: any;
  list: any[];
  all: any[];
  profil?: any;
}

const initialState: sectorData = {
  current: {},
  all: [],
  list: [],
  profil: {},
};

export const sectorSlice = createSlice({
  name: "sectors",
  initialState,
  reducers: {
    setSector: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setSectors: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllSectors: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllSectors, setSector, setSectors } = sectorSlice.actions;
export default sectorSlice.reducer;
