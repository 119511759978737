import { RootState } from "../redux/store";
import { Typography, Row, Col, List, Divider, Collapse } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;
const Establishment = () => {
  const divisions = useSelector((state: RootState) => state.division.list);

  const renderItem = (item: any, key: any) => {
    return (
      <List.Item key={`ets_${key}`}>
        <Text
          style={{
            color: "#FFF",
            fontSize: 14,
            marginBottom: 2,
            textAlign: "center",
            paddingBottom: 8,
            borderBottom: "solid 0.1px #CCC4",
            display: "block",
          }}
        >
          {item.name}
        </Text>
        <div style={{ color: "#FFF" }}>
          <Row gutter={32} align="middle">
            {item?.subDivisions?.map((p: any, i: any) => (
              <Col className="list__item" key={`division_${i}`} span={12}>
                <span style={{ fontWeight: "lighter", fontSize: 11 }}>
                  {p?.name}
                </span>
              </Col>
            ))}
          </Row>
        </div>
      </List.Item>
    );
  };
  return (
    <>
      {divisions.length > 0 && (
        <div style={{ padding: "15px 0px" }}>
          <Collapse>
            <Collapse.Panel
              key="tab"
              className="footer__collapse"
              header={
                <div>
                  <Text
                    style={{
                      color: "#FFF",
                      marginBottom: 0,
                      marginTop: 0,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    className="box-title"
                  >
                    Les provinces éducationnelles
                  </Text>
                </div>
              }
            >
              <div style={{ padding: "0 20px " }}>
                <List
                  dataSource={divisions}
                  renderItem={renderItem}
                  grid={{ gutter: 8, column: 5, xs: 1, md: 2, sm: 2, lg: 3 }}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
          <Divider style={{ background: "#FFF", margin: "auto 0px" }} />
        </div>
      )}
    </>
  );
};

export default Establishment;
