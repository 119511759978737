import { Typography, Row, Col, Divider } from "antd";
import { Link } from "react-router-dom";
import { routes } from "../routes";
const { Paragraph } = Typography;

const Copyright = () => {
  const date = new Date();
  const anneeActuelle = date.getFullYear();

  return (
    <div>
      <Divider style={{ background: "#FFF", marginBottom: 5 }} />
      <Row
        gutter={10}
        justify="space-around"
        align="middle"
        style={{ padding: "10px 8px" }}
      >
        <Col className="copyright" xs={24} md={8}>
          <Paragraph
            style={{
              color: "white",
            }}
          >
            @{anneeActuelle} ministere de la formation professionnelle
          </Paragraph>
        </Col>
        <Col className="links" xs={24} md={8}>
          {routes
            .filter((p) => p.isMenu)
            .map((p, i) => {
              return (
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    margin: "5px",
                  }}
                  to={p.path}
                  key={`${i}`}
                >
                  {p.label}
                </Link>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};

export default Copyright;
