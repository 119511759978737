import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../routes";

export const BootstrapNavigation = (props: any) => {
  const pathname = useLocation().pathname;
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ position: "sticky", top: 0, left: 0 }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll logo" href="#page-top">
            <img src={require("../../assets/images/logo.png")} alt="aaaa" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {routes
              .filter((p) => p.isMenu)
              .map((p, i) => {
                const isActive =
                  p.path === pathname || p.path === `${pathname}/`;
                return (
                  <li
                    key={`nav__${i}_li`}
                    className={`${isActive ? "active" : ""}`}
                  >
                    <Link
                      to={p.path}
                      className={`page-scroll ${pathname === p.path ? "active" : ""}`}
                    >
                      {p.label}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
