import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface centerData {
  current: any;
  list: any[];
  all: any[];
  profil: any;
  sectors: any[];
  plugs: any[];
  plug: any;
}

const initialState: centerData = {
  current: {},
  all: [],
  list: [],
  profil: {},
  sectors: [],
  plugs: [],
  plug: {},
};

export const centerSlice = createSlice({
  name: "centers",
  initialState,
  reducers: {
    setCenter: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setPlug: (state, action: PayloadAction<any>) => {
      state.plug = action.payload;
    },
    setCenterProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCenters: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllCenters: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setPlugs: (state, action: PayloadAction<any[]>) => {
      state.plugs = action.payload;
    },
    setCenterSectors: (state, action: PayloadAction<any[]>) => {
      state.sectors = action.payload;
    },
  },
});
export const {
  setAllCenters,
  setCenter,
  setCenterProfil,
  setCenters,
  setCenterSectors,
  setPlugs,
  setPlug,
} = centerSlice.actions;
export default centerSlice.reducer;
