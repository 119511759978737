"use client";
import { configureStore } from "@reduxjs/toolkit";
import centerReducer from "./centerReducer";
import divisionReducer from "./divisionReducer";
import sectorReducer from "./sectorReducer";
import subDivisionReducer from "./subDivisionReducer";
import userReducer from "./userReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    center: centerReducer,
    division: divisionReducer,
    subDivision: subDivisionReducer,
    sector: sectorReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
