"use client";
import authService from "../services/authService";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";

export function LoginComponent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  async function onLogin() {
    const data: any = { password: "admin", username: "adminyekola@online.com" };
    //setLoading(true);
    await userService
      .login(data)
      .then(async (response) => {
        const result = response.data;
        const id = result?.data?.roles?.id;
        const token = result?.token;
        if (!isEmpty(token)) {
          authService.setToken(token);
          authService.setLogin(data);
        }
      })
      .catch((reason) => {
        const error = userService.getError(reason);
      });
    //setLoading(false);
  }

  const onInit = React.useCallback(async () => {
    setLoading(true);
    await onLogin();
    await userHelper.initData(dispatch);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);

  return (
    <>
      <div className={`preloader`}>
        <div id="preloader" className={`${!loading ? "close" : ""}`}>
          <div id="loader"></div>
        </div>
      </div>
    </>
  );
}
